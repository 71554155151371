<template>
    <div class="lvya-aboutUS">
        <div class="about-div1">
            <img src="static/lvya/aboutUS/yjy01.png" alt="" style="width:100%;height:100%;">
        </div>
        <div class="about-div2">
            <div class="tip-div">
                <p class="tip-top">研究院介绍</p>
                <!-- <p class="tip-center">COMPANY&nbsp;INTRODUCTION</p> -->
                <p class="tip-line"></p>
            </div>
            <div class="gsjs">
                <img src="static/lvya/aboutUS/yjy02.png" alt="" class="gsjs-img">
                <div class="text_div">
                    <p class="text_div_p1">北京金岭信息技术研究院</p>
                    <p class="text_div_p2">北京金岭信息技术研究院始终聚焦教育科研和技术研发的研究，领先十年探索教育改革系统方案，汇聚国内外科学高效的教学法，研发普适化的教学工具，服务于国家、政府及学校科技人才培养。<br/>通过技术平台让科学的教学法，成为普通学校可使用的学生能力提升系统，高效提高学生认知学习、元认知学习、情感学习的能力。</p>
                    <div class="list_div">
                        <div class="list">
                            <img src="static/lvya/aboutUS/yjy04.png" alt="" class="list_img">
                            <p class="list_p">认知学习</p>
                        </div>
                        <div class="list">
                            <img src="static/lvya/aboutUS/yjy05.png" alt="" class="list_img">
                            <p class="list_p">元认知学习</p>
                        </div>
                        <div class="list">
                            <img src="static/lvya/aboutUS/yjy03.png" alt="" class="list_img">
                            <p class="list_p">情感学习</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { BaiduMap, BmControl, BmView, BmAutoComplete, BmLocalSearch, BmMarker } from 'vue-baidu-map'
export default {
    components: {
        // BaiduMap,
        // BmControl,
        // BmView,
        // BmAutoComplete,
        // BmLocalSearch,
        // BmMarker
    },
    data() {
        return {
            showMapComponent: this.value,
            keyword: '',
            mapStyle: {
            width: '100%',
            height: this.mapHeight + 'px'
            },
            center: { lng: 116.420394, lat: 40.004966 },
            // center: { lng: 40.004888, lat: 116.420216 },
            zoom: 20,
            map: '',
        }
    },
    watch: {
      value: function(currentValue) {
        this.showMapComponent = currentValue
        if (currentValue) {
          this.keyword = ''
        }
      }
    },
    methods: {
        getClickInfo(e) {
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        },
        syncCenterAndZoom(e) {
            const { lng, lat } = e.target.getCenter()
            this.center.lng = lng
            this.center.lat = lat
            this.zoom = e.target.getZoom()
      },
    },
    created() {
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = new BMapGL.Map('gpsMain2') // 创建Map实例
        // this.map.centerAndZoom('', ) // 初始化地图,设置中心点坐标和地图级别
        // eslint-disable-next-line no-undef
        this.map.centerAndZoom(new BMapGL.Point(116.420394, 40.004966), this.zoom)
        this.map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
        // eslint-disable-next-line no-undef
        var marker1 = new BMapGL.Marker(new BMapGL.Point(116.420394, 40.004966))
        this.map.addOverlay(marker1)
    },
}
</script>

<style scoped>
.about-div2{
    width: 100%;
    margin: 0px auto;
    padding: 0 0 50px 0;
}
.tip-div {
    line-height: 70px;
    padding: 80px 0 50px 0;
    text-align: center;
}
.tip-top {
    font-size: 40px;
    color: #333;
}
.tip-center {
    font-size: 45px;
    color: #ccc;
}
.tip-line {
    width: 100px;
    height: 9px;
    background: #1BB876;
    margin: 15px auto;
    border-radius: 10px;
}
.gsjs {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 80px;
}
.gsjs-img {
    max-width: 452px!important;
    /* height: 355px; */
}
.text_div{
    padding: 30px 0 30px 40px;
    box-sizing: border-box;
}
.text_div_p1{
    font-size: 22px;
    /* font-weight: bold; */
    color: #333;
}
.text_div_p2{
    color: #666;
    margin-top: 20px;
    line-height: 40px;
}
.list_div{
    display: flex;
}
.list{
    width: 80px;
    text-align: center;
    /* background: red; */
    margin-top: 35px;
    margin-left: 60px;
}
.list:first-child{
    margin-left: 0;
}
.list_img{
    margin-bottom: 10px;
}
.list_p{
    font-size: 14px;
    color: #333;
}

</style>
